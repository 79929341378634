<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_MY_REFERRAL')" type="back" :settings="false" />
    </template>

    <section class="referral-main">
      <div class="container">
        <h2>
          {{ $t.getTranslation("LBL_SUMMARY") }}
        </h2>

        <f7-list class="summary-list">
          <f7-list-item :header="$t.getTranslation('LBL_NUMBER_OF_REFERRED_CUSTOMERS')" :title="$h.formatNumber(referralInfo?.TotalReferredUsers || 0)"></f7-list-item>
          <f7-list-item :header="$t.getTranslation('LBL_NUMBER_OF_REFERRED_ORDERS')" :title="$h.formatNumber(referralInfo?.TotalReferredOrders || 0)"></f7-list-item>

          <template v-if="referralInfo?.UserGroupId > 1">
            <f7-list-item :header="$t.getTranslation('LBL_TOTAL_ELIGIBLE_COMMISSION_AMOUNT')" :title="$h.formatPrice(referralInfo?.TotalEligibleCommissionAmount || 0)"></f7-list-item>
            <f7-list-item :header="$t.getTranslation('LBL_TOTAL_REDEEMABLE_COMMISSION_AMOUNT')" :title="$h.formatPrice(referralInfo?.TotalRedeemableCommissionAmount || 0)"></f7-list-item>
            <f7-list-item :header="$t.getTranslation('LBL_TOTAL_REDEEMED_COMMISSION_AMOUNT')" :title="$h.formatPrice(referralInfo?.TotalRedeemedCommissionAmount || 0)"></f7-list-item>
          </template>
        </f7-list>

        <h2>
          {{ $t.getTranslation("LBL_DETAILS") }}
        </h2>
        <f7-list>
          <f7-list-item :title="$t.getTranslation('LBL_REFERRED_CUSTOMERS')" link="/profile/referrallist/"></f7-list-item>
          <f7-list-item :title="$t.getTranslation('LBL_ELIGIBLE_ORDER')" link="/profile/eligilbeorderlist/"></f7-list-item>
        </f7-list>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref, defineAsyncComponent } from "vue";

import { get } from "@/utils/axios";

import { useStore } from "@/store";

// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "ReferralMainPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    const referralInfo = ref([]);

    const store = useStore();
    
    const getReferralInfo = async () => {
      let returnData = await get("/user/referral/info");
      referralInfo.value = returnData;
    };

    onMounted(() => {
      getReferralInfo();
    });

    return {
      referralInfo,
    };
  },
});
</script>
